import React, {useEffect} from 'react';
import {toast, ToastContainer} from 'react-toastify';
import ReactGa from 'react-ga';
import {CssBaseline, CssVarsProvider} from '@mui/joy';
import theme from 'Theme';

import Router from './Route';
import {homeUrl} from './shared/utils/apiEndPoints';

import 'react-toastify/dist/ReactToastify.css';

toast.configure({
	draggable: false,
	hideProgressBar: true,
	position: toast.POSITION.TOP_RIGHT
});

function App() {
	useEffect(() => {
		if (homeUrl === 'portalsekolah.co.id') ReactGa.initialize('UA-220083682-1');
	}, []);
	return (
		<CssVarsProvider theme={theme}>
			<CssBaseline />
			<Router />
			<ToastContainer />
		</CssVarsProvider>
	);
}

export default App;
